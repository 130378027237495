<template>
  <div class="OpenAccount3">
    <div class="top">
      <span class="font">第1步 </span>
      <span class="font">第2步 </span>
      <span class="font">第3步 </span>
      <span class="font">第4步 </span>
    </div>
    <div class="title">
      <span>关联银行卡</span>
    </div>
    <form action="demo_form.php">
      <div class="commonType">
        <span class="bodyStar">* </span>
        <span class="bodyTitle"> 银行名称</span>
      </div>
      <input v-model="queryParams.homeTel" class="bodyType" type="text" name="fname"><br>
      <div class="commonType">
        <span class="bodyStar">* </span>
        <span class="bodyTitle"> 银行卡号</span>
      </div>
      <input v-model="phone" class="bodyType" type="text" name="fname"><br>
      <div class="commonType">
        <span class="bodyStar">* </span>
        <span class="bodyTitle"> 银行预留手机号码</span>
      </div>
      <input v-model="queryParams.homeTel" class="bodyType" type="text" name="fname"><br>


      <div class="commonType">
        <span class="bodyStar">* </span>
        <span class="bodyTitle"> 图形验证码</span>
      </div>
      <input v-model="queryParams.homeTel" class="bodyType" style="width: 30%" type="text" name="fname"><br>
      <div class="login-code">
        <img :src="codeUrl" @click="getCode"  />
      </div>
      <div class="commonType">
        <span class="bodyStar">* </span>
        <span class="bodyTitle"> 短信验证码</span>
      </div>
      <input v-model="messageCode" class="bodyType" type="text" name="fname"><br>
      <button

              class="get_verification"
              @click.prevent="getMessageCode">
        {{ computeTime > 0 ? `(${computeTime}s)已发送` : "获取验证码" }}
      </button>
      <div class="next" @click="nextStep">
        <span class="nextFont">下一步</span>
      </div>
      <span class="return">返回</span>
    </form>
  </div>
</template>

<script>
  import { getCodeImg } from "@/api/login";
  export default {
    name: "OpenAccount3",

    data() {
      return {
        codeUrl: "",
        phone: "",
        messageCode: "",
        computeTime: 0,
        queryParams: {
          ofundProfCode: null,
          income: null,
          beneficiaryType: null,
          beneficiaryGender: null,
          beneficiary: null,
          beneficiaryIdType: null,
          beneficiaryIdno: null,
          beneficiaryIdValidate: null,
          beneficiaryBirthday: null,
          beneficiaryNationality: null,
          beneficiaryProfCode: null,
          beneficiaryTel: null,
          beneficiaryEMail: null,
          beneficiaryZipcode:null,
          beneficiaryAddress: null,
          taxIdentity: null,
          homeTel: null,
          email: null,
          zipcode: null
        }
      }
    },
    components: {},
    methods: {
      nextStep() {
        alert(JSON.stringify(this.queryParams))
      },
      getCode() {
        getCodeImg().then((res) => {
          this.codeUrl = "data:image/gif;base64," + res.img;
        });
      },
      getMessageCode() {
        if (!this.computeTime) {
          this.computeTime = 30;
          this.timer = setInterval(() => {
            this.computeTime--;
            if (this.computeTime <= 0) {
              clearInterval(this.timer);
            }
          }, 1000);
        }
        //获取验证码
        return "123456";
      },
    },

    mounted() {
      this.getCode();
      //状态分类(业绩领先,海外分红,潜力指数)
      // var type = this.$route.params;
    },
  };
</script>

<style scoped>
  .OpenAccount3 {
    width: 1200px;
    height: 1277px;
    opacity: 1;
    background: #ffffff;
    box-shadow: 0px 4px 12px 0px #edf1f7;
    margin: auto;
  }

  .top {
    width: 1200px;
    height: 146px;
    opacity: 1;
    background: #ffffff;
    box-shadow: 0px 4px 12px 0px #edf1f7;
    text-align: center;
    line-height: 146px;
  }

  .font {
    width: 175px;
    height: 24px;
    opacity: 1;
    font-size: 24px;
    font-weight: 700;
    color: #ce9b63;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
  }

  .title {
    width: 269px;
    height: 18px;
    opacity: 1;
    font-size: 18px;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
    font-weight: 700;
    text-align: LEFT;
    color: #8691a8;
    line-height: 18px;
    margin-top: 44px;
    margin-left: 190px;
    margin-bottom: 43px;
  }



  .bodyTitle {
    width: 81px;
    height: 16px;
    opacity: 1;
    font-size: 16px;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
    font-weight: 400;
    text-align: LEFT;
    line-height: 16px;
    margin-bottom: 14px;
  }




  .bodyStar {
    width: 81px;
    height: 16px;
    opacity: 1;
    font-size: 16px;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #c5231c;
    line-height: 16px;
    margin-left: 191px;
    margin-bottom: 14px;
  }

  .bodyType {
    width: 520px;
    height: 48px;
    opacity: 1;
    background: #ffffff;
    border: 1px solid #e0e2e8;
    border-radius: 2px;
    margin-left: 191px;
    margin-bottom: 24px;
  }





  .next {
    width: 200px;
    height: 48px;
    opacity: 1;
    background: linear-gradient(103deg, #f7d9b7 0%, #eabf96 100%);
    border-radius: 2px;
    text-align: center;
    margin-left: 191px;
    margin-top: 44px;
    display: inline-block;
  }

  .return {
    width: 32px;
    height: 16px;
    opacity: 1;
    font-size: 16px;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #ce9b63;
    line-height: 16px;
    margin-left: 28px;
  }

  .nextFont {
    width: 48px;
    height: 16px;
    opacity: 1;
    font-size: 16px;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #b88141;
    line-height: 48px;
  }
  .commonType{
    margin-bottom: 14px;
  }
</style>
